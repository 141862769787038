import { useState } from 'react';
import styles from './search-panel.module.css';
import SearchTabs from '@/Components/search-panel/search-tabs';
import SearchPanelTabs from '@/Components/search-panel/search-panel-tabs/search-panel-tabs';
import WorkSearchForm from '@/Components/search-panel/work-search-form/work-search-form';
import StudySearchForm from '@/Components/search-panel/study-search-form/study-search-form';
import TourSearchForm from '@/Components/search-panel/tour-search-form/tour-search-form';
import { DivisionSearchPanel } from '@/Lib/types/division';
import { RegionSearchPanel } from '@/Lib/types/region';
import { ActivityTagsByDivisionId } from '@/Lib/types/activity-tags';
import { TourOperatorAndDestinationSearchPanel } from '@/Lib/types/trip';
import { useAtom } from 'jotai';
import { activeTabAtom } from '@/State/global/global.store';

type Props = {
  change: any;
  workExperienceDropDown: DivisionSearchPanel[];
  workDestinationDropDown: RegionSearchPanel[];
  studyExperienceDropDown: ActivityTagsByDivisionId[];
  studyDestinationDropDown: RegionSearchPanel[];
  tourOperatorsAndDestinations: TourOperatorAndDestinationSearchPanel[];
};

const SearchPanel: React.FC<any> = ({
  change,
  workExperienceDropDown,
  workDestinationDropDown,
  studyExperienceDropDown,
  studyDestinationDropDown,
  tourOperatorsAndDestinations,
}: Props) => {
  const [activeTabContextGlobalState, setActiveTabGlobalState] =
    useAtom(activeTabAtom);

  const [activeTab, setActiveTab] = useState(activeTabContextGlobalState);
  const changeTab = (tab: any) => {
    setActiveTab(tab);
    change(tab);
    setActiveTabGlobalState(tab);
  };

  return (
    <div
      className={`flex flex-col items-center max-h-fit lg:h-100 pb-10 pt-14 px-5 lg:px-0 lg:pb-0 lg:pt-20 text-white bg-aqua ${styles.wrapper} lg:mb-32`}
    >
      <h1 className="relative text-3.5xl leading-9 lg:text-3.125rem font-bold mx-auto mb-2 lg:mb-5">
        Be more than a tourist️.
        <span className="text-2.5xl absolute top-1 lg:bottom-4 -right-2">
          &trade;
        </span>
      </h1>
      <p className="text-base leading-5 font-bold lg:text-2xl lg:font-normal text-white lg:text-23 pb-4.5 lg:pb-14">
        The easiest way to work & travel overseas
      </p>

      <div
        className={`text-black w-full md:max-w-30 lg:max-w-none lg:w-215 bg-white rounded-md relative ${styles.panel}`}
      >
        <div className="border-b-2 border-solid border-light-800">
          <SearchPanelTabs
            activeTab={activeTab}
            onTabChange={(tab) => {
              changeTab(tab);
            }}
          />
        </div>
        <div className="p-5 text-center lg:p-8">
          {activeTab === SearchTabs.WORK && (
            <WorkSearchForm
              divisions={workExperienceDropDown}
              destinations={workDestinationDropDown}
            />
          )}
          {activeTab === SearchTabs.STUDY && (
            <StudySearchForm
              experiences={studyExperienceDropDown}
              destinations={studyDestinationDropDown}
            />
          )}
          {activeTab === SearchTabs.TOUR && (
            <TourSearchForm data={tourOperatorsAndDestinations} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchPanel;
