import { GImage } from '../g-image';
import { currencySymbols } from '@/Data/currency';
import styles from './superlite-product-card.module.css';
import { useCurrency } from '@/Lib/hooks/useCurrency';

type Props = {
  phonesClassName?: string;
  largeHeading?: boolean;
  largeTitleGap?: boolean;
};

const SuperliteProductCardDesktop = ({
  phonesClassName = '',
  largeHeading = false,
  largeTitleGap = false,
}: Props) => {
  const { currency } = useCurrency();

  return (
    <div className="flex w-full justify-center items-center">
      <div className="w-full h-full flex">
        <a
          className="w-full bg-superliteBlue rounded-md overflow-hidden border border-light-700 relative cursor-pointer"
          style={{
            height: '100.5%',
            boxShadow: 'rgba(0, 187, 228, 0.15) 2px 2px 6px 0px',
          }}
          href="https://superlite.travel/?utm_source=gwat&utm_medium=homepage&utm_campaign=superlite"
          target="_blank"
        >
          <div className="text-white font-semibold pth-full flex flex-col justify-center content-center">
            <div className="flex flex-col w-full">
              <div
                className={`text-center relative top-6 ${
                  largeTitleGap ? 'mb-4' : ''
                }`}
              >
                <h6>Introducing...</h6>
                <h4 className="text-white text-3xl mt-1">The SuperLite App</h4>
              </div>

              <div className="flex flex-row w-full justify-center gap-32">
                <div className="flex flex-col justify-end pb-14">
                  <div className="text-white text-xs font-normal">
                    The new DIY travel app for working holidays.
                  </div>
                  <div
                    className={`text-white text-5.5xl font-bold my-4 ${
                      largeHeading ? '!text-3xl' : ''
                    }`}
                  >
                    <div>Work Overseas</div>
                    <div className="">
                      The <span className={styles.stroke}>Easy</span> Easiest
                      Way
                    </div>
                  </div>
                  <div className="text-white text-2xs font-normal">
                    Make Friends. Get Visa. Find Jobs. Save Money. Stress Less.
                  </div>
                  <div className="text-white text-sm font-thin pt-4">
                    Get instant access{' '}
                    <span
                      className="font-normal underline decoration-0.5"
                      style={{
                        textDecorationThickness: '0.5px',
                      }}
                    >
                      for just {currencySymbols[currency]}99
                    </span>{' '}
                    <span className="font-normal">- limited time only.</span>
                  </div>
                  <div className="flex mt-4">
                    <div className="bg-superliteRed text-light-600 bottom-8 px-4 py-2 rounded-md text- leading-6 font-semibold">
                      Join the Community
                    </div>
                  </div>
                </div>

                <GImage
                  path="website/static/superlite-card/mobile-desktop.webp"
                  alt="protected umbrella icon"
                  visibleByDefault
                  classes={`!w-400 h-full object-cover ${phonesClassName}`}
                  wrapperClass="!w-auto justify-end mt-4 relative top-1"
                />
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export { SuperliteProductCardDesktop };
